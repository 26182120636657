import $ from 'jquery'
import 'es6-shim'
import notifyWhenChange from '../events/notify-when-change'

function BreakpointUtils (breakpoints) {
  const _self = this

  /* public */
  _self.isBreakPointUp = breakpoint => {
    return $(window).width() >= getBreakpointLowerBound(breakpoint)
  }

  /* public */
  _self.isBreakPointDown = breakpoint => {
    return $(window).width() < getBreakpointUpperBound(breakpoint)
  }

  /* public */
  _self.isBreakPointOnly = breakpoint => {
    return (
      _self.isBreakPointUp(breakpoint) && _self.isBreakPointDown(breakpoint)
    )
  }

  /* public */
  _self.isBreakPointAny = breakpoints => {
    for (let index = 0; index < breakpoints.length; index++) {
      const breakpoint = breakpoints[index]
      if (_self.isBreakPointOnly(breakpoint)) return true
    }
    return false
  }

  /* public */
  _self.isBreakPointBetweenInclude = (fromBreakpoint, toBreakpoint) => {
    return (
      _self.isBreakPointUp(fromBreakpoint) &&
      _self.isBreakPointDown(toBreakpoint)
    )
  }

  _self.isBreakPointBetween = (fromBreakpoint, toBreakpoint) => {
    return _self.isBreakPointBetweenInclude(
      fromBreakpoint,
      getRelativeBreakpoint(toBreakpoint, -1)
    )
  }

  /* public */
  _self.notifyWhen = notifyWhenChange.bind(
    null,
    cb => {
      window.addEventListener('resize', cb)
    },
    cb => {
      window.removeEventListener('resize', cb)
    }
  )

  /* private */
  function getRelativeBreakpoint (breakpoint, relativePosition) {
    const keys = Object.keys(breakpoints)
    const breakpointPosition = keys.indexOf(breakpoint)
    const relativeBreakpoint = keys[breakpointPosition + relativePosition]
    if (relativeBreakpoint !== undefined) {
      return relativeBreakpoint
    } else {
      return null
    }
  }

  /* private */
  function getNextBreakpoint (breakpoint) {
    return getRelativeBreakpoint(breakpoint, +1)
  }

  /* private */
  function getBreakpointLowerBound (breakpoint) {
    return breakpoints[breakpoint]
  }

  /* private */
  function getBreakpointUpperBound (breakpoint) {
    const nextBreakpoint = getNextBreakpoint(breakpoint)
    if (nextBreakpoint !== null) {
      return breakpoints[nextBreakpoint]
    }
    return Number.POSITIVE_INFINITY
  }
}

export default BreakpointUtils
