export default function notifyWhenChange (
  addEventListenerCallback,
  removeEventListenerCallback,
  check,
  callback,
  runInitial
) {
  let lastState = null

  let callbackIfTrue = () => {
    const state = check()
    if (lastState !== state) {
      lastState = state
      callback(state)
    }
  }

  if (runInitial) callbackIfTrue()

  addEventListenerCallback(callbackIfTrue)

  return function () {
    this.drop = () => {
      removeEventListenerCallback(callbackIfTrue)
    }
  }
}
