import $ from 'jquery'
import MenuDynamicElementController from './lib/menu-dynamic-element-controller'
export default function initCollectionMenuController () {
  $(() => {
    var dynamicElementContainer = $('#header-dynamic-element')[0]

    // We can deduct the normal header height from #header padding-top.
    // Given header does not have a bottom padding (0), we can use this method.
    // Also see: https://stackoverflow.com/questions/590602/padding-or-margin-value-in-pixels-as-integer-using-jquery
    var $header = $('#header')
    var headerHeight = $header.innerHeight() - $header.height()

    if (dynamicElementContainer) {
      var menuDynamicElementController = new MenuDynamicElementController({
        dynamicElementContainer: dynamicElementContainer
      })

      var collectionSections = $('[data-collection-dynamicheader]')
      $.each(collectionSections, function (i, collectionSection) {
        var collectionHeaderElement = $(collectionSection).find(
          '.collection-header'
        )[0]
        var collectionId = $(collectionSection).attr('id')
        var positionTarget = $(collectionSection).find(
          '[data-dynamic-menu-target]'
        )[0]

        if (collectionHeaderElement && positionTarget && collectionId) {
          var dynamicElementIdentifier = 'collection-' + collectionId

          menuDynamicElementController.registerDynamicElement(
            dynamicElementIdentifier,
            collectionHeaderElement.cloneNode(true)
          )

          menuDynamicElementController.registerScrollPosition(
            dynamicElementIdentifier,
            function () {
              return $(positionTarget).offset().top - headerHeight
            },
            function () {
              return (
                $(positionTarget).offset().top +
                $(positionTarget).height() -
                headerHeight
              )
            }
          )
        }
      })

      menuDynamicElementController.init()
    }
  })
}
