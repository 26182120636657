function repeatTimeout (cb, interval, times) {
  let count = 0

  let timeout = () => {
    if (count < times) {
      count++
      window.setTimeout(() => {
        cb()
        timeout()
      }, interval)
    }
  }

  timeout()
}

export default repeatTimeout
