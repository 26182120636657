import $ from 'jquery'
import 'slick-carousel'
export default function initPresentationHorizontalScrollPhotolayout () {
  $(() => {
    $('[data-presentation-horizontal-scroll-photolayout]').each((i, el) => {
      let $slickElement = $(el)
      $slickElement.slick({
        infinite: true,
        slidesToScroll: 1,
        variableWidth: true,
        speed: 800,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnFocus: false,
        pauseOnHover: false,
        mobileFirst: true,
        prevArrow: null,
        nextArrow: null,
        rows: 0
      })
    })
  })
}
