import $ from 'jquery'

export default function MenuController () {
  var self = this

  var scrolled = false
  var collapsible = false
  var open = false

  self.setScrolled = function (newScrolled) {
    // if different
    if (scrolled !== newScrolled) {
      scrolled = newScrolled
      if (scrolled) {
        $('body').addClass('scrolled')
      } else {
        $('body').removeClass('scrolled')
      }
    }
  }

  self.isOpen = function () {
    return open
  }
  self.isCollapsible = function () {
    return collapsible
  }

  self.init = function () {}
}
