import 'objectFitPolyfill'
import $ from 'jquery'

/* global objectFitPolyfill */

const objectFitPolyFillIfNecessary = el => {
  if (el) {
    if ($(el).attr('data-object-fit')) objectFitPolyfill(el)
  } else {
    objectFitPolyfill()
  }
}

export default objectFitPolyFillIfNecessary
