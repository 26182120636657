import $ from 'jquery'
import EventEmitter from 'eventemitter3'

const SlidesController = function (containerElement) {
  let _slides = null

  function _initiateSlides () {
    if (_slides === null) _slides = $(containerElement).children()
  }

  this.getSlides = () => {
    _initiateSlides()
    return _slides
  }
}

export default function ImageLoopController (options) {
  const _this = this
  let _settings

  /* dependencies */
  let _slidesController = null

  /* state */
  let _currentIndex = null
  let _currentSlide = null
  let _currentTimer = null

  _this.eventEmitter = new EventEmitter()

  /* public */
  _this.init = function () {
    _settings = $.extend(
      {
        containerElement: null,
        interval: 200
      },
      options
    )

    _slidesController = new SlidesController(_settings.containerElement)

    // const slides = _slidesController.getSlides()
    _currentIndex = 0
  }

  /* public */
  _this.start = function () {
    $(_settings.containerElement).css({ 'will-change': 'content' })
    if (_currentTimer === null) {
      if (_currentSlide === null) {
        _notifyNextSlide(getSlideByIndex(0))
        _showByIndex(0)
      }
      _currentTimer = setInterval(function () {
        _nextSlide()
      }, _settings.interval)
    }
  }

  /* public */
  _this.stop = function () {
    if (_currentTimer !== null) {
      clearInterval(_currentTimer)
      _currentTimer = null
    }
  }

  /* private */
  function _nextSlide () {
    let newIndex = getNextIndex(_currentIndex)
    _showByIndex(newIndex)
  }

  /* private */
  function getNextIndex (index) {
    const slides = _slidesController.getSlides()
    return (index + 1) % slides.length
  }

  /* private */
  function getSlideByIndex (index) {
    const slides = _slidesController.getSlides()
    return slides[index]
  }

  /* private */
  function _showByIndex (index) {
    const slides = _slidesController.getSlides()
    const _nextSlide = slides[index]
    if (_nextSlide) {
      _this.eventEmitter.emit('slideWillChange', _nextSlide)
      if (_currentSlide) {
        const slideToShow = _nextSlide
        const slideToHide = _currentSlide

        $(slideToHide).addClass('keep-visible')
        $(slideToHide).removeClass('current-slide')

        $(slideToShow).css({ 'animation-duration': '200ms' })
        $(slideToShow).addClass('image-fade-in')

        // Animation complete
        setTimeout(() => {
          $(slideToHide).removeClass('keep-visible')
          $(slideToShow).removeClass('image-fade-in')
          $(slideToShow).css({ 'will-change': '' })
        }, 200)
      }
      $(_nextSlide).addClass('current-slide')
      _currentIndex = index
      _currentSlide = _nextSlide
      _this.eventEmitter.emit('slideChanged', _currentSlide)
    }
    let nextIndex = getNextIndex(_currentIndex)
    _notifyNextSlide(getSlideByIndex(nextIndex))
  }

  function _notifyNextSlide (nextSlide) {
    $(nextSlide).css({ 'will-change': 'display, opacity' })
    _this.eventEmitter.emit('slideWillBeNext', nextSlide)
  }
}
