import $ from 'jquery'
import ImageLoopController from './lib/image-loop-controller'
import listenPresentationTemplateActive from './lib/listen-presentation-template-active'
import objectFitPolyFillIfNecessary from './lib/object-fit-polyfill'

export default function initPresentationFullscreenPhotoLoop (
  lazyLoadingManager,
  breakpointUtils
) {
  console.log('initPresentationFullscreenPhotoLoop')
  $(() => {
    $('[data-presentation-fullscreen-photo-loop]').each((i, el) => {
      console.log('initPresentationFullscreenPhotoLoop for element', el)

      let presentationElement = el
      let $presentationElement = $(presentationElement)

      let intervalSetting = parseInt(
        $presentationElement.attr(
          'data-presentation-fullscreen-photo-loop-interval'
        )
      )
      if (intervalSetting < 1) intervalSetting = 2000

      // $photoContainer.children('.photo').each(function (element) {
      //   let $img = $(this)
      //     .children('img')
      //     .first()

      //   let coverImage = new CoverImage({
      //     element: this,
      //     containerElement: $photoContainer[0],
      //     originalSize: new coverImageUtils.Rectangle(
      //       parseInt($img.attr('width')),
      //       parseInt($img.attr('height'))
      //     )
      //   })

      //   coverImage.init()
      // })
      // TODO: use data-object-fit

      const imageLoopController = new ImageLoopController({
        containerElement: presentationElement,
        interval: intervalSetting
      })

      imageLoopController.eventEmitter.on('slideWillBeNext', slide => {
        const lazyLoadElements = $(slide)
          .find('.lazy-load')
          .not('.lazy-loaded')
          .toArray()

        lazyLoadElements.forEach(el => {
          lazyLoadingManager.loadEventEmitter.once(el, () => {
            // console.log('objectfitpolyfill', el)
            // objectFitPolyFillIfNecessary(el)

            $(el)
              .find('img')
              .each((i, imgel) => {
                console.log('objectfitpolyfill', imgel)
                objectFitPolyFillIfNecessary(imgel)
              })
          })
          lazyLoadingManager.bLazy.load(el, true)
        })
      })

      imageLoopController.eventEmitter.on('slideChanged', slide => {
        console.log('slideChanged')

        $(slide)
          .find('img')
          .each((i, imgel) => {
            console.log('objectfitpolyfill', imgel)
            objectFitPolyFillIfNecessary(imgel)
          })
      })

      imageLoopController.init()

      // Activate / deactivate depending on breakpoints
      let breakpointsManaged = listenPresentationTemplateActive(
        breakpointUtils,
        presentationElement,
        isActive => {
          if (isActive) {
            console.log('activate slideshow')
            imageLoopController.start()
          } else {
            console.log('deactivate slideshow')
            imageLoopController.stop()
          }
        }
      )

      if (!breakpointsManaged) {
        console.log('not managed')
        imageLoopController.start()
      }
    })
  })
}
