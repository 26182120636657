import Blazy from 'blazy'
import $ from 'jquery'
import EventEmitter from 'eventemitter3'
import repeatTimeout from '../utility-functions/repeat-timeout'

export default function LazyLoadingManager (breakpointUtils) {
  const _this = this

  _this.bLazy = new Blazy({
    selector: '.lazy-load',
    successClass: 'lazy-loaded',
    offset: 200,
    success: function (el) {
      // Image has loaded
      // Do your business here
      _this.bLazy.revalidate()
      notifySubscribersOfLoad(el)
    },
    error: function (el, msg) {
      if (msg === 'missing') {
        // Data-src is missing
      } else if (msg === 'invalid') {
        // Data-src is invalid
      }
    }
  })

  _this.loadEventEmitter = new EventEmitter()

  const notifySubscribersOfLoad = el => {
    _this.loadEventEmitter.emit(el)
  }

  // Run blazy revalidate when page has fully loaded
  $(() => {
    _this.bLazy.revalidate()

    repeatTimeout(
      () => {
        _this.bLazy.revalidate()
      },
      150,
      3
    )

    repeatTimeout(
      () => {
        _this.bLazy.revalidate()
      },
      2000,
      3
    )
  })
}
