import $ from 'jquery'
import EventEmitter from 'eventemitter3'

let ResponsiveVideoSourceController = function (options) {
  const _this = this
  let _settings

  /* state */
  let _currentVideoSize = null

  _this.eventEmitter = new EventEmitter()

  /* public */
  _this.init = function () {
    _settings = $.extend(
      {
        blazy: null,
        videoContainerElement: null,
        videoSources: null,
        srcAttribute: 'src',
        posterAttribute: 'poster'
      },
      options
    )

    // TODO: find default styles (object-fit, backgroundcolor) from DOM
    if (_settings.videoSources === null) {
      _settings.videoSources = _findVideoSources()
    }
    _sortVideoSources(_settings.videoSources)

    _bindUIActions()
    _updateCurrentVideoSources()
  }

  /* public */
  _this.destroy = function () {
    _unbindUIActions()
  }

  /* private */
  function _findOptimalVideoSize () {
    const containerWidth = $(_settings.videoContainerElement).width()
    let optimalVideoSize = _settings.videoSources.find(
      videoSource => videoSource.width >= containerWidth
    )
    if (optimalVideoSize == null) {
      optimalVideoSize =
        _settings.videoSources[_settings.videoSources.length - 1]
    }
    return optimalVideoSize
  }

  /* private */
  function _useVideoSize (videoSize) {
    if (_currentVideoSize !== videoSize) {
      _currentVideoSize = videoSize
      // let playing = false
      // const videoElement = _getVideoElement()

      // TODO: check if playing / autoplay
      // playing = !(videoElement.paused || videoElement.ended) || videoElement.autoplay

      // videoElement.pause()

      // Set the video element's sources
      _setElementMediaSources(videoSize)

      // Set the video's size (width and height) attributes
      _setMediaSize(videoSize)

      _this.eventEmitter.emit('sourceChanged')
      console.log('emitted sourceChanged')
    }
  }

  function _setMediaSize (videoSize) {
    // Video
    const videoElement = _getVideoElement()
    if (videoElement) {
      $(videoElement).attr('width', videoSize.width)
      $(videoElement).attr('height', videoSize.height)
    }
  }

  /* private */
  function _setElementMediaSources (videoSize) {
    // Video
    const videoElement = _getVideoElement()
    const $videoElement = $(videoElement)

    $videoElement.children('source').remove()

    if (videoSize.files) {
      const sourceElements = videoSize.files.map(file => {
        const elAttributes = {
          type: file.mime
        }
        elAttributes[_settings.srcAttribute] = file.url
        return $('<source>', elAttributes)
      })

      $videoElement.prepend(sourceElements)
    }

    const posterFile = videoSize.posterFile || ''
    $videoElement.attr(_settings.posterAttribute, posterFile)

    // $videoElement.attr('poster', '')
    // $videoElement.attr('data-poster', mediaSource.imageSourceFile || false)
  }

  /* private */
  function _updateCurrentVideoSources () {
    const optimalVideoSize = _findOptimalVideoSize()
    _useVideoSize(optimalVideoSize)
  }

  /* private */
  function _sortVideoSources (videoSources) {
    videoSources.sort((a, b) => a.width - b.width) // ascending
  }

  /* private */
  function _findVideoSources () {
    let videoSources = []
    const videoSourcesJson = $(_getVideoElement()).attr('data-video-sources')
    if (videoSourcesJson) {
      const videoSourcesArr = JSON.parse(videoSourcesJson)
      if ($.isArray(videoSourcesArr)) {
        videoSources = videoSourcesArr
      }
    }
    return videoSources
  }

  /* private */
  function _getVideoElement () {
    return $(_settings.videoContainerElement)
      .find('>video')
      .first()[0]
  }

  /* private */
  function _bindUIActions () {
    window.addEventListener('resize', _updateCurrentVideoSources)
  }

  /* private */
  function _unbindUIActions () {
    window.removeEventListener('resize', _updateCurrentVideoSources)
  }
}

export default ResponsiveVideoSourceController
