import 'es6-shim'
import $ from 'jquery'

// Global tooling
import 'lazysizes'
import 'lazysizes/plugins/parent-fit/ls.parent-fit.js' // Parent-fit-plugin for lazysizes, make data-sizes="auto" work well with object-fit
import 'lazysizes/plugins/object-fit/ls.object-fit'

import objectFitPolyFillIfNecessary from './lib/object-fit-polyfill'
import initMenuController from './init-menu-controller'
import initCollectionMenuController from './init-collection-menu-controller'
import initPresentationHorizontalScrollPhotolayout from './init-presentation-horizontal-scroll-photolayout'
import initPresentationFullscreenPhotoLoop from './init-presentation-fullscreen-photo-loop'
import LazyLoadingManager from './common-util/lazy-loading/lazy-loading-manager'
import BreakpointUtils from './common-util/responsive/breakpoint-utils'
import initResponsiveVideoSources from './init-responsive-video-sources'
import initPhotoSwipeFromDOM from './init-photoswipe'

const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  xxxl: 2000
}

const breakpointUtils = new BreakpointUtils(breakpoints)

const lazyLoadingManager = new LazyLoadingManager()

initMenuController()
initCollectionMenuController()
initPresentationHorizontalScrollPhotolayout()
initPresentationFullscreenPhotoLoop(lazyLoadingManager, breakpointUtils)

initResponsiveVideoSources(lazyLoadingManager)

$(() => {
  // photoswipe
  var photoSwipeObj = initPhotoSwipeFromDOM('[data-pswp-container]', 'a[data-pswp-thumb]')

  objectFitPolyFillIfNecessary()
})
