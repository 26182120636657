import $ from 'jquery'
import ResponsiveVideoSourceController from './common-util/video/responsive-video-source-controller'
import objectFitPolyFillIfNecessary from './lib/object-fit-polyfill'

export default function initResponsiveVideoSources (lazyLoadingManager) {
  $(() => {
    $('[data-video-sources]').each((i, el) => {
      const container = el.parentElement
      const responsiveVideoSourceController = new ResponsiveVideoSourceController(
        {
          videoContainerElement: container,
          srcAttribute: 'data-src',
          posterAttribute: 'poster'
        }
      )

      const _getContainerElement = () => {
        return container
      }

      const _getVideoElement = () => {
        return el
      }

      const _getImgElement = () => {
        let $container = $(_getContainerElement())
        return $container.find('>img').first()[0]
      }

      // TODO: externalize to: Autoplay-video-image-fallback-controller

      const _showVideo = () => {
        let $container = $(_getContainerElement())
        $container.addClass('use-video')
        const videoElement = _getVideoElement()
        objectFitPolyFillIfNecessary(videoElement)
      }

      const _showImage = () => {
        let $container = $(_getContainerElement())
        $container.removeClass('use-video')
        const imgElement = _getImgElement()
        objectFitPolyFillIfNecessary(imgElement)
      }

      const _tryPlayVideo = (attempts, interval, failHandler) => {
        const videoElement = _getVideoElement()

        let attempt = 0

        const attemptPlay = onFail => {
          console.log('attempt play')
          let playPromise = videoElement.play()
          if (playPromise !== undefined) {
            playPromise
              .then(() => {
                console.log('Playing')
                // Playing \o/
              })
              .catch(error => {
                if (attempt < attempts) {
                  attempt++
                  setTimeout(() => {
                    attemptPlay(onFail)
                  }, interval)
                } else {
                  console.log('Failed')
                  onFail(error)
                }
              })
          }
        }

        if (videoElement) {
          attemptPlay(error => {
            console.log('Play rejected second', error)
            if (failHandler) {
              failHandler()
            }
          })
        } else {
          failHandler()
        }
      }

      responsiveVideoSourceController.eventEmitter.on('sourceChanged', () => {
        _showVideo()

        const videoElement = _getVideoElement()
        $(videoElement).removeClass('lazy-loaded')

        console.log('subscribe')
        lazyLoadingManager.loadEventEmitter.once(el, () => {
          console.log('onElementLoadHandler')
          _tryPlayVideo(3, 150, () => {
            _showImage()
          })
        })
        lazyLoadingManager.bLazy.load(videoElement, true)
      })

      responsiveVideoSourceController.init()
    })
  })
}
