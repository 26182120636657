import $ from 'jquery'
import MenuController from './lib/menu-controller'

export default function initMenuController () {
  var menuController = new MenuController()

  menuController.init()

  var updateScroll = function (animate) {
    var scrolledPx = $(document).scrollTop()
    menuController.setScrolled(scrolledPx > 0)
  }

  $(() => {
    updateScroll()
    $(window).scroll(updateScroll)
  })
}
