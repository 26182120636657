import $ from 'jquery'
import * as PhotoSwipe from 'photoswipe'
import * as photoSwipeUiDefault from 'photoswipe/dist/photoswipe-ui-default'

export default function initPhotoSwipeFromDOM (gallerySelector, thumbSelector) {
  var parseThumbnailElements = function (el) {
    var thumbElements = $(el).find(thumbSelector)

    var numNodes = thumbElements.length

    var items = []

    var childElements

    var size

    var item

    for (var i = 0; i < numNodes; i++) {
      el = thumbElements[i]

      // include only element nodes
      if (el.nodeType !== 1) {
        continue
      }

      childElements = el.children

      size = el.getAttribute('data-pswp-size').split('x')

      // create slide object
      item = {
        src: el.getAttribute('href'),
        w: parseInt(size[0], 10),
        h: parseInt(size[1], 10)
      }

      item.el = el // save link to element for getThumbBoundsFn

      if (childElements.length > 0) {
        item.msrc = childElements[0].getAttribute('src') // thumbnail url
        if (childElements.length > 1) {
          item.title = childElements[1].innerHTML // caption (contents of figure)
        }
      }

      var mediumSrc = el.getAttribute('data-pswp-med')
      if (mediumSrc) {
        size = el.getAttribute('data-pswp-med-size').split('x')
        // "medium-sized" image
        item.m = {
          src: mediumSrc,
          w: parseInt(size[0], 10),
          h: parseInt(size[1], 10)
        }
      }
      // original image
      item.o = {
        src: item.src,
        w: item.w,
        h: item.h
      }

      items.push(item)
    }

    return items
  }

  var onThumbnailsClick = function (e) {
    var clickedListItem

    if ($(e.target).is(thumbSelector)) clickedListItem = e.target
    else {
      clickedListItem = $(e.target).closest(thumbSelector)[0]
    }

    if (!clickedListItem) {
      return false
    }

    var clickedGallery = $(clickedListItem).closest(gallerySelector)[0]

    if (!clickedGallery) {
      return false
    }

    var childNodes = $(clickedGallery).find(thumbSelector)

    var numChildNodes = childNodes.length

    var nodeIndex = 0

    var index

    for (var i = 0; i < numChildNodes; i++) {
      if (childNodes[i] === clickedListItem) {
        index = nodeIndex
        break
      }
      nodeIndex++
    }

    if (index >= 0) {
      openPhotoSwipe(index, clickedGallery, true)
    }

    return false
  }

  var photoswipeParseHash = function () {
    var hash = window.location.hash.substring(1)

    var params = {}

    if (hash.length < 5) {
      // pid=1
      return params
    }

    var vars = hash.split('&')
    for (var i = 0; i < vars.length; i++) {
      if (!vars[i]) {
        continue
      }
      var pair = vars[i].split('=')
      if (pair.length < 2) {
        continue
      }
      params[pair[0]] = pair[1]
    }

    if (params.gid) {
      params.gid = parseInt(params.gid, 10)
    }

    return params
  }

  var openPhotoSwipe = function (
    index,
    galleryElement,
    disableAnimation,
    fromURL
  ) {
    var pswpElement = document.querySelectorAll('.pswp')[0]

    var gallery

    var options

    var items

    items = parseThumbnailElements(galleryElement)
    
    // define options (if needed)
    options = {
      galleryUID: galleryElement.getAttribute('data-pswp-uid'),

      // getThumbBoundsFn: function (index) {
      //   // See Options->getThumbBoundsFn section of docs for more info
      //   var thumbnail = items[index].el.children[0]

      //   var pageYScroll =
      //     window.pageYOffset || document.documentElement.scrollTop

      //   var rect = thumbnail.getBoundingClientRect()

      //   return { x: rect.left, y: rect.top + pageYScroll, w: rect.width }
      // },
      barsSize: { top: 0, bottom: 0 },
      showHideOpacity: false,
      captionEl: false,
      fullscreenEl: false,
      shareEl: false,
      captionAndToolbarHide: false,
      bgOpacity: 1,
      tapToToggleControls: false
    }

    if (fromURL) {
      if (options.galleryPIDs) {
        // parse real index when custom PIDs are used
        // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
        for (var j = 0; j < items.length; j++) {
          if (items[j].pid === index) {
            options.index = j
            break
          }
        }
      } else {
        options.index = parseInt(index, 10) - 1
      }
    } else {
      options.index = parseInt(index, 10)
    }

    // exit if index not found
    if (isNaN(options.index)) {
      return
    }

    if (disableAnimation) {
      options.showAnimationDuration = 0
    }


    // Pass data to PhotoSwipe and initialize it
    gallery = new PhotoSwipe(pswpElement, photoSwipeUiDefault, items, options)

    // see: http://photoswipe.com/documentation/responsive-images.html
    var realViewportWidth

    var useLargeImages = false

    var firstResize = true

    var imageSrcWillChange

    gallery.listen('beforeResize', function () {
      var dpiRatio = window.devicePixelRatio ? window.devicePixelRatio : 1
      dpiRatio = Math.min(dpiRatio, 2.5)
      realViewportWidth = gallery.viewportSize.x * dpiRatio

      if (
        realViewportWidth >= 1200 ||
        (!gallery.likelyTouchDevice && realViewportWidth > 800) ||
        window.screen.width > 1200
      ) {
        if (!useLargeImages) {
          useLargeImages = true
          imageSrcWillChange = true
        }
      } else {
        if (useLargeImages) {
          useLargeImages = false
          imageSrcWillChange = true
        }
      }

      if (imageSrcWillChange && !firstResize) {
        gallery.invalidateCurrItems()
      }

      if (firstResize) {
        firstResize = false
      }

      imageSrcWillChange = false
    })

    gallery.listen('gettingData', function (index, item) {
      if (useLargeImages) {
        item.src = item.o.src
        item.w = item.o.w
        item.h = item.o.h
      } else {
        item.src = item.m.src
        item.w = item.m.w
        item.h = item.m.h
      }
    })

    gallery.init()
  }

  // select all gallery elements
  var galleryElements = document.querySelectorAll(gallerySelector)
  for (var i = 0, l = galleryElements.length; i < l; i++) {
    galleryElements[i].setAttribute('data-pswp-uid', i + 1)
    galleryElements[i].onclick = onThumbnailsClick
  }

  // Parse URL and open gallery if it contains #&pid=3&gid=1
  var hashData = photoswipeParseHash()
  if (hashData.pid && hashData.gid) {
    openPhotoSwipe(hashData.pid, galleryElements[hashData.gid - 1], true, true)
  }

  return {
    openPhotoSwipe: function (index, galleryElement, disableAnimation, fromURL) {
      openPhotoSwipe(index, galleryElement, disableAnimation, fromURL)
    }
  }
}
