import $ from 'jquery'
export default function MenuDynamicElementController (options) {
  var _this = this
  var _settings

  var _dynamicElements = {}
  var _registeredScrollPositions = []
  var _latestDynamicElementIdentifier = null

  /**
   * public
   */
  _this.init = function () {
    _settings = $.extend(
      {
        dynamicElementContainer: null
      },
      options
    )
    _bindUIActions()
  }

  /**
   * public
   */
  _this.registerDynamicElement = function (dynamicElementIdentifier, element) {
    _dynamicElements[dynamicElementIdentifier] = element
  }

  /**
   * public
   */
  _this.registerScrollPosition = function (
    dynamicElementIdentifier,
    startPositionTeller, // Function returning the starting position (px from top) | null
    endPositionTeller // Function returning the ending position (px from top) | null
  ) {
    _registeredScrollPositions.push({
      dynamicElementIdentifier: dynamicElementIdentifier,
      startPositionTeller: startPositionTeller,
      endPositionTeller: endPositionTeller
    })
  }

  /**
   * private
   */
  var updateScroll = function () {
    var scrolledPx = $(document).scrollTop()
    var currentRegisteredScrollPosition = determineRegisteredScrollPosition(
      scrolledPx
    )
    applyRegisteredScrollPosition(currentRegisteredScrollPosition)
  }

  /**
   * private
   */
  var getAbsoluteElementPositions = function () {
    var absoluteElementPositions = []

    for (var index = 0; index < _registeredScrollPositions.length; index++) {
      var item = _registeredScrollPositions[index]

      absoluteElementPositions.push({
        startPosition: item.startPositionTeller
          ? item.startPositionTeller()
          : null,
        endPosition: item.endPositionTeller ? item.endPositionTeller() : null,
        dynamicElementIdentifier: item.dynamicElementIdentifier
      })
    }

    return absoluteElementPositions
  }

  /**
   * private
   */
  var determineRegisteredScrollPosition = function (scrolledPx) {
    var mostLikelyScrollPosition = null

    // console.log('run');

    var absoluteScrollPositions = getAbsoluteElementPositions()
    for (var index = 0; index < absoluteScrollPositions.length; index++) {
      var pos = absoluteScrollPositions[index]

      // console.log(pos);

      if (
        (pos.startPosition != null && scrolledPx < pos.startPosition) ||
        (pos.endPosition != null && scrolledPx >= pos.endPosition)
      ) {
        continue
      }

      if (mostLikelyScrollPosition == null) {
        mostLikelyScrollPosition = pos
        continue
      }

      if (pos.startPosition > mostLikelyScrollPosition.startPosition) {
        mostLikelyScrollPosition = pos
      }
    }

    return mostLikelyScrollPosition
  }

  /**
   * private
   */
  var applyRegisteredScrollPosition = function (
    currentRegisteredScrollPosition
  ) {
    var currentDynamicElementIdentifier =
      currentRegisteredScrollPosition == null
        ? null
        : currentRegisteredScrollPosition.dynamicElementIdentifier

    if (_latestDynamicElementIdentifier !== currentDynamicElementIdentifier) {
      var dynamicElement = _dynamicElements[currentDynamicElementIdentifier]

      if (_settings.dynamicElementContainer) {
        $(_settings.dynamicElementContainer).empty()
        $(_settings.dynamicElementContainer).append(dynamicElement)
      }
      _latestDynamicElementIdentifier = currentDynamicElementIdentifier
    }
  }

  /**
   * private
   */
  var _bindUIActions = function () {
    // Bind to scroll event
    $(window).scroll(updateScroll)
    $(window).resize(updateScroll)
    $(document).ready(updateScroll)
  }
}
