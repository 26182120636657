import $ from 'jquery'

export default function listenPresentationTemplateActive (
  breakpointUtils,
  element,
  onStatusChange
) {
  if (breakpointUtils) {
    const screenSizesElement = $(element).closest('[data-screensizes-json]')[0]
    if (screenSizesElement) {
      const screenSizesJson = $(screenSizesElement).attr(
        'data-screensizes-json'
      )
      if (screenSizesJson) {
        const screensizes = JSON.parse(screenSizesJson)

        if (screensizes instanceof Array && screensizes.length > 0) {
          breakpointUtils.notifyWhen(
            breakpointUtils.isBreakPointAny.bind(null, screensizes),
            isActiveScreensize => {
              onStatusChange(isActiveScreensize)
            },
            true
          )
          return true
        }
      }
    }
  }

  return false
}
